import { render, staticRenderFns } from "./UserInviteDialog.vue?vue&type=template&id=41888a2b&scoped=true"
import script from "./UserInviteDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./UserInviteDialog.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41888a2b",
  null
  
)

export default component.exports