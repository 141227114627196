import { render, staticRenderFns } from "./UserSendActivationDialog.vue?vue&type=template&id=677d75c2&scoped=true"
import script from "./UserSendActivationDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./UserSendActivationDialog.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "677d75c2",
  null
  
)

export default component.exports